


function moduleSlider(){
	if($('.m__slider .slider-slick li').length > 1){
		$('.slider-slick').slick({
			dots: true,
			arrows:false,
			infinite: true,
			autoplay:true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			responsive: [
		    {
		      breakpoint: 890,
		      settings: {
		        variableWidth: false,
		      }
		    }
		  ]
		});
	}
}