


function moduleMenus(){

	$('.menus-nav li').click(function(){
		var target = $(this).data('menu');


		$('.menus-nav li').removeClass('active');
		$(this).addClass('active');

		$('.menu').removeClass('active');
		$('#menu-'+target).addClass('active');


	})

}