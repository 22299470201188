
$(document).ready(function(){
	init(); 










});










function init(){

	var mediumBP = 990;


	new WOW().init();
	
	//Header JS
	header();

	//Page Hero Module
	modulePageHero();

	//Map Block
	//moduleMapBlock();

	//Menus Module
	moduleMenus();

	//Slider Module
	moduleSlider();

	//Quote Slider Module
	moduleQuoteSlider();

	//Events module
	moduleEvents();

	//Zonal Module
	moduleZonalSignup();

	//Matchpint 
	initMatchpint();

	// Rotating Text Items
	rotatingText();

	// FAQs
	initFAQs();

	// Acteol
	initActeol();

	landingPageFunctions();

	//Snow
	initSnow();


	//Revealing header logic
	window.scrollCache = 0;
	$(window).scroll(function() {
		headerScroll();
	});





	//Popup Logic
	initPopups();



}




