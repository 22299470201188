
function initMatchpint(){
	//if container div exists then we are on the right page and need to run function
	if( $('#matchpint-container').length ){

		var matchpintFeedURL 	= '/wp-content/themes/duplo/library/php/fetch-matchpint.php?matchpintID='
		var matchpintID 		= $('#matchpint-container').attr('data-matchpint-id'); //Grabbing the id

		matchpintFeedURL = matchpintFeedURL+matchpintID+'&nocache='+(Math.floor(Math.random() * 9999)); //Adds a random number between 0-9999 to stop any php caching
		console.log('Matchpint URL: '+matchpintFeedURL);

		//load the matchpint data direct to on-page div
		if(matchpintID){
			//alert(matchpintID);
			$('#matchpint-container').load(matchpintFeedURL, function(){
				//Succesfully loaded the feed!
				$('.m__matchpint').addClass('loaded');
				$('#js__matchpint-table').DataTable({
			        "info"			: false,
			        "lengthChange"	: false
			    });
			});
		}
	}

	// Sports Filter
	if ( $('.js-sports-filter').length ) {
		const options = {
			arrowButtonMarkup: '<div class="plus-icon"><span></span></div>',
			labelBuilder: '<span class="very-small-regular txt__light text-center text-uppercase">{text}</span>',
		};

		$('.js-sports-filter').selectric(options);

		$('.js-sports-filter').on('selectric-change change', function(event, element, selectric) {
			const filter = event.target.value;

			if ( filter === 'all' ) {
				$('.js-matchpint-item').removeClass('d-none');
			} else {
				$('.js-matchpint-item[data-sport="'+filter+'"]').removeClass('d-none');
				$('.js-matchpint-item').not('[data-sport="'+filter+'"]').addClass('d-none');
			}
		});
	}
}