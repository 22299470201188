function initFAQs() {
    // Selectric Init
    var options = {
        arrowButtonMarkup: '<div class="plus-icon"><span></span></div>',
        labelBuilder: '<span class="small-regular txt__light text-center text-uppercase">{text}</span>',
    }
    $('.js-selectric').selectric(options);

    // Toggle FAQs Questions
    const questions = $('.js-faqs-question');

    if ( questions.length ) {
        questions.each(function() {
            $(this).on('click', function(e) {
                e.preventDefault();

                $(this).toggleClass('showing');
                $(this).next().slideToggle();
            });
        });
    }

    // FAQs Filters
    $('.js-faqs-filter').on('click', function(e) {
        e.preventDefault();

        const filter = $(this).data('filter');

        if ( $(this).hasClass('active') ) {
            $('.faqs-section__item').removeClass('d-none');
            $(this).removeClass('active');
        } else {
            $('.faqs-section__item').each(function() {
                const cats = $(this).data('categories');
                
                if ( !cats.includes(filter) ) {
                    $(this).addClass('d-none');
                } else {
                    $(this).removeClass('d-none');
                }
            });
    
            $('.js-faqs-filter').removeClass('active');
            $(this).addClass('active');
        }
    });
}