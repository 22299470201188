function initActeol() {
    if ( $('#acteol-date').length ) {
        $('#acteol-date').datepicker({
            format: 'dd/mm/yyyy',
            date: new Date(1992, 1, 1),
            endDate: new Date()
        });
    }

    $('#acteol-signup').submit(function(event){
		event.preventDefault();

		$('.signup__wrapper').addClass('submitting');

		var loginForm = $('#acteol-signup');

		console.log(loginForm.attr('action'));

		$.ajax({
			url: loginForm.attr('action'),
			type: 'POST',
			data: loginForm.serialize(),
			success: function(result) {
				console.log('Ajax Request Succeded');

		    	$('.signup__wrapper').removeClass('submitting');
		    	$('.signup__wrapper').addClass('submitted');
		    	$('#finish-message').text(result);

			},
			error: function(xhr, textStatus, errorThrown){
				//Couldn't connect to server side file
		       	console.log('Ajax Request Failed');
		       	$('.signup__wrapper').removeClass('submitting');
		       	$('.signup__wrapper').addClass('submitted');
		    	$('#finish-message').text('Connection failure, please refresh and try again');
		    }
		});

	});    
}