


function initPopups(){

	var popupContainer = $('.popup-wrapper');

	$(document).on('click', '.js__popup, .button', function (event) {

		var targetPopup = $(this).data('popup');

		if(targetPopup > ''){

			event.preventDefault();

			popupContainer.addClass('active');
			$('#'+targetPopup).addClass('active');
			$('body').addClass('popup-active');

			//Popup Conditional JS
			if(targetPopup == 'newsletter'){
				$('#newsletter-email').val( $('#temp-email').val() );
				$('#newsletter-date').datepicker({
					format: 'dd/mm/yyyy',
					date: new Date(1992, 1, 1),
					endDate: new Date()
				});
			}

			return false; //Double deactivate link

		}

	});


	$('#newsletter-signup').submit(function(event){
		event.preventDefault();

		$('.signup__wrapper').addClass('submitting');

		var loginForm = $('#newsletter-signup');

		console.log(loginForm.attr('action'));

		$.ajax({
			url: loginForm.attr('action'),
			type: 'POST',
			data: loginForm.serialize(),
			success: function(result) {
				console.log('Ajax Request Succeded');

		    	$('.signup__wrapper').removeClass('submitting');
		    	$('.signup__wrapper').addClass('submitted');
		    	$('#finish-message').text(result);

			},
			error: function(xhr, textStatus, errorThrown){
				//Couldn't connect to server side file
		       	console.log('Ajax Request Failed');
		       	$('.signup__wrapper').removeClass('submitting');
		       	$('.signup__wrapper').addClass('submitted');
		    	$('#finish-message').text('Connection failure, please refresh and try again');
		    }
		});

	});



	$('.js__popup-close').click(function(event){
		event.preventDefault();

		popupContainer.removeClass('active');
		$('.popup').removeClass('active');
		$('body').removeClass('popup-active');
	});





	///Auto Popup logic...
	if($('.popup[data-auto-popup="true"]').length > 0){
		var showPopup = getCookie('showPopup');

		if(!showPopup){
			$('.popup-wrapper').addClass('active');
			$('.popup[data-auto-popup="true"]').addClass('active');
			$('body').addClass('popup-active');
			setCookie('showPopup','true',1);
		}
		

	}


	function setCookie(name,value,days) {
	    var expires = "";
	    if (days) {
	        var date = new Date();
	        date.setTime(date.getTime() + (days*24*60*60*1000));
	        expires = "; expires=" + date.toUTCString();
	    }
	    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}
	function getCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	}
	function eraseCookie(name) {   
	    document.cookie = name+'=; Max-Age=-99999999;';  
	}





	$('.js__booking-widgets-select').change(function(){
		$('.popup__widgets-item').removeClass('active');

		$('.popup__widgets-item[data-booking-widget="'+$('.js__booking-widgets-select option:selected').data('booking-widget')+'"]').addClass('active');

	})



	//"#wpcf7-f403-o1" TODO: Work out way for dynamically showing thankyou popup (this is the ID of the botanist one)

}