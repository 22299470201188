function landingPageFunctions() {
    $('#landing-date').datepicker({
        format: 'dd/mm/yyyy',
        date: new Date(1992, 1, 1),
        endDate: new Date()
    });

    $('#landing-signup').submit(function(event){
		event.preventDefault();

		$('.atreemo-form-wrapper').addClass('submitting');

		var loginForm = $('#landing-signup');

		$.ajax({
			url: loginForm.attr('action'),
			type: 'POST',
			data: loginForm.serialize(),
			success: function(result) {
				console.log('Ajax Request Succeded');

		    	$('.atreemo-form-wrapper').removeClass('submitting');
		    	$('.atreemo-form-wrapper').addClass('submitted');

			},
			error: function(xhr, textStatus, errorThrown){
				//Couldn't connect to server side file
		       	console.log('Ajax Request Failed');
		       	$('.atreemo-form-wrapper').removeClass('submitting');
		       	$('.atreemo-form-wrapper').addClass('submitted');
		    	$('#finish-message').text('Connection failure, please refresh and try again');
		    }
		});
	});    
}