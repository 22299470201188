function modulePageHero() {
	if ( $('.m__page-hero .slider-wrapper li').length > 1 ) {
		$('.slider-wrapper').slick({
			autoplay: true,
	  		autoplaySpeed: 5000,
	  		fade: true
		});
	}

	if ( $('.mph__custom-code #video') ) {
		/* Disclaimer for future Devs trying to find the youtube API code */
		////////////////////////////////////////////////////////////////////
		/* Code is located in page_hero.php because YT are dumb, and can't */
		/* call their function if it is inside another function :((        */
	}

	// Hero Section Alt
	if ( $('.js-page-hero-alt-slider').length ) {
		var i = 1; $('.js-page-hero-alt-slider').each(function() {
			$(this).addClass('js-page-hero-alt-slider-'+i);

			var slider = $('.js-page-hero-alt-slider-'+i);
			var prevArrow = '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7135 0.215391L0.4294 11.5584C-0.143133 12.1335 -0.143133 13.0659 0.4294 13.6411L11.7135 24.984C11.9994 25.2716 12.4637 25.2716 12.7496 24.984L13.7856 23.9427C14.0715 23.6551 14.0715 23.1889 13.7856 22.9013L4.05471 13.1208C3.76881 12.8332 3.76881 12.3662 4.05471 12.0794L13.7856 2.2981C14.0715 2.01052 14.0715 1.54432 13.7856 1.25674L12.7496 0.215391C12.4637 -0.0721855 11.9994 -0.0721855 11.7135 0.215391Z" fill="white"/></svg>';
			var nextArrow = '<svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.28645 25.1843L13.5706 13.8413C14.1431 13.2662 14.1431 12.3337 13.5706 11.7586L2.28645 0.415632C2.00055 0.128056 1.53634 0.128056 1.25044 0.415632L0.214424 1.45699C-0.0714742 1.74456 -0.0714743 2.21076 0.214424 2.49834L9.94529 12.2789C10.2312 12.5665 10.2312 13.0334 9.94529 13.3203L0.214422 23.1016C-0.0714761 23.3891 -0.0714761 23.8553 0.214422 24.1429L1.25044 25.1843C1.53633 25.4718 2.00055 25.4718 2.28645 25.1843Z" fill="white"/></svg>';
			var arrowContainer = $(this).closest('.page-hero-alt').find('.js-hero-slider-arrows');
			var progressBar = $(this).closest('.page-hero-alt').find('.page-hero-alt__slider-progress');
			var currentSlideElem = $(this).closest('.page-hero-alt').find('.page-hero-alt__slider-current')

			slider.on('init', function(event, slick){
				progressBar.addClass('animating');
			});

			slider.slick({
				autoplay: true,
				autoplaySpeed: 5000,
				speed: 1000,
				dots: false,
				arrows: true,
				nextArrow: "<div class='slick-arrow slick-next'>"+nextArrow+"</div>",
				prevArrow: "<div class='slick-arrow slick-prev'>"+prevArrow+"</div>",
				appendArrows: arrowContainer,
				fade: true,
				draggable: false,
				pauseOnFocus: false,
				pauseOnHover: false,
				mobileFirst: true,
				responsive: [{
					breakpoint: 991,
					settings: {
						dots: true,
						appendDots: arrowContainer
					}
				}] 
			});
			
			slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
				progressBar.removeClass('animating');

				var currentSlideIndex = nextSlide + 1;
				
				currentSlideElem.html(currentSlideIndex);
			});
			slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
				progressBar.addClass('animating');
			});

			i++;
		});
		
	}
}
