


function moduleEvents(){

	$('.events-popup').click(function(event){
		event.preventDefault();
		var target = $(this).data('events-popup');
		$('#'+target).addClass('active');
	})

	$('.events-close').click(function(){
		$(this).parent().removeClass('active');
	})

}