


function moduleZonalSignup(){

	$('#zonal-date').datepicker({
		format: 'dd/mm/yyyy',
		date: new Date(1992, 1, 1),
		endDate: new Date()
	});

	$('#zonal-signup').submit(function(event){
		event.preventDefault();

		$('.zonal__wrapper').addClass('submitting');

		var loginForm = $('#zonal-signup');

		console.log(loginForm.attr('action'));

		$.ajax({
			url: loginForm.attr('action'),
			type: 'POST',
			data: loginForm.serialize(),
			success: function(result) {
				console.log('Ajax Request Succeded');

		    	$('.zonal__wrapper').removeClass('submitting');
		    	$('.zonal__wrapper').addClass('submitted');

			},
			error: function(xhr, textStatus, errorThrown){
				//Couldn't connect to server side file
		       	console.log('Ajax Request Failed');
		       	$('.zonal__wrapper').removeClass('submitting');
		       	$('.zonal__wrapper').addClass('submitted');
		    	$('#finish-message').text('Connection failure, please refresh and try again');
		    }
		});

	});


}