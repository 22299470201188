function rotatingText() {
    var scrollingTextElems = document.querySelectorAll('.rotating-text-items__container');
    
    if ( scrollingTextElems ) {
        scrollingTextElems.forEach((scrollingTextElem) => {
            var links = scrollingTextElem.querySelectorAll('.rotating-text-items__item');
            var speed = isDesktop() ? (links.length / 2) / 8 : (links.length / 2) / 10;
            var paddingRight = parseFloat(gsap.getProperty(links[0], 'marginRight', 'px')) + parseFloat(gsap.getProperty(links[0], 'paddingRight', 'px'));
            
            // Weird issue with the width messing up if the font hasn't loaded in fully so wrapping in a setTimeout function
            setTimeout(() => {            
                var tl = horizontalLoop(links, {
                    repeat: -1,
                    speed: speed,
                    reversed: false,
                    paddingRight: paddingRight // otherwise first element would be right up against the last when it loops. In this layout, the spacing is done with marginRight.
                });
            }, 500);
        });
    }
}

function isDesktop() {
    return window.innerWidth >= 992;
}