


function header(){

	$('.hamburger').click(function(){
		$(this).toggleClass('active');
		if($('.nav-popup').hasClass('active')){
			$('.nav-popup').removeClass('active');
			if($(window).width() < 450){
				$('body').removeClass('popup-active');
			}
		}else{
			$('.nav-popup').addClass('active');
			if($(window).width() < 450){
				$('body').addClass('popup-active');
			}
		}
	})

	if($(window).width() > 990){
		$(window).scroll(function() {
			var scroll = getCurrentScroll();
			if((scroll % 3) == 0){ //Every 3rd pixel 
				if(scroll > 200){
					//Scrolling back up and not too close to the 'main' header
					$('header').addClass('scroll');
				}else{	
					//Scrolling down
					$('header').removeClass('scroll');
				}

			}
		});
	};




	//Header 3//Always hamburger nav
	if($('.header-3')){

		
		$(".nav-popup").on("click touchend", ".open-children", function (e) {
			showChildMenu($(this).data('child-group'));
		})

		$('.nav-link-wrapper.has-children').each(function() {
			var children = $('#child-items-' + $(this).data('child-group'));
			var maxHeight = 0;

			$(this).find('a').each(function() {
				var elelmHeight = $(this).outerHeight();
				maxHeight = maxHeight + elelmHeight;
			});

			children.css('max-height', maxHeight);
		});

		$('.nav-link-wrapper').hover(
			function(){ //Hover on
				showChildMenu($(this).data('child-group'));
			}, 
			function(){ //Hover off
				showChildMenu($(this).data('child-group'));
			}
		)

		$('.nav-link-wrapper > a').hover(
			function(){ //Hover on
				$('.nav-link-wrapper > a').addClass('inactive');
				$(this).removeClass('inactive');
			}, 
			function(){ //Hover off
				$('.nav-link-wrapper > a').removeClass('inactive');
			}
		)
	}

}

function showChildMenu($id){

	$('.open-children.menu-parent-wrapper-'+$id).toggleClass('active');

	$('.child-items-'+$id).toggleClass('active');

}



function headerScroll(){

	var scroll = getCurrentScroll();

	if((scroll % 3) == 0){ //Every 3rd pixel

		if(scroll > 200){ //Not at the top of the page

			if((scrollCache > scroll)){
				//Scrolling back up and not too close to the 'main' header
				$('.header').removeClass('scroll-back').addClass('scroll');
			}else{	
				//Scrolling down
				$('.header').removeClass('scroll').addClass('scroll-back');
			}

		}else{

			$('.header').removeClass('scroll').removeClass('scroll-back');

		}
		scrollCache = scroll;
		

	}
}

function getCurrentScroll() {
	return window.pageYOffset || document.documentElement.scrollTop;
}	


